/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType } from '~/@types/models'
import ProductItem from '~/components/product-item'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import { space } from '~/gatsby-plugin-theme-ui'
import isolateFilters from '~/utils/filtering'

type Props = CollectionTemplateType

const CollectionRugs: FC<Props> = ({ pageContext: { data } }) => {
  const filterData = isolateFilters(data)

  return (
    <CollectionLayout
      title={data.title}
      description={data.description}
      image={data.image && data.image.originalSrc}
      ankleContent={data.metafields?.ankleContent}
    >
      <CollectionProducts
        data-template="collection-rugs-stacked"
        collectionHandle={data.handle}
        initialData={data.products}
        pagination={data.pagination}
        filters={filterData}
        data={data}
        gridVariant="layout.template-rugs"
      />
    </CollectionLayout>
  )
}

export default CollectionRugs
